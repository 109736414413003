import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

const StyledGatsbyLink = styled(GatsbyLink)`
text-decoration: underline;
font-weight: 500;
color: ${({ theme }) => theme.colors.navy};
`;

const A = styled.a`
text-decoration: underline;
font-weight: 500;
color: ${({ theme }) => theme.colors.navy};
`;

export const renderGatsbyLink = (url, label) => (
  <span key={`${url}_${label || url}`}>
    <StyledGatsbyLink to={url.replace('https://tilli.fr', '')}>{label || url}</StyledGatsbyLink>
  </span>
);

export const renderALink = (url, label) => (
  <span key={`${url}_${label}`}>
    <A href={url} target="_blank" rel="noopener noreferrer">{label}</A>
  </span>
);
