import React from 'react';
import styled from 'styled-components';
import { Title1 as StyledTitle1 } from './v3/styledComponents';
import Dots from './v3/Dots';
import Question from './Question';
import {
  mostFrequentQuestions,
  securityQuestions,
  serviceQuestions,
  paymentsQuestions,
  customerCareQuestions,
  otherQuestions,
} from './faqQuestions';
import mostFrequentIcon from '../../assets/faq/mostFrequentIcon.svg';
import serviceIcon from '../../assets/faq/serviceIcon.svg';
import paymentsIcon from '../../assets/faq/paymentsIcon.svg';
import customerCareIcon from '../../assets/faq/customerCareIcon.svg';
import otherIcon from '../../assets/faq/otherIcon.svg';

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 66px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 50px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const MostFrequentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 0px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 34px 0px 63px;
  }
`;

const OtherQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 68px 0px 32px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 50px 0px 37px;
  }
`;

const Title1 = styled(StyledTitle1)`
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 0px 15px;
  }
`;

const QuestionsContainer = styled.div`
  width: 1166px;
  padding: ${({ noPadding }) => noPadding ? '0px' : '32px 0px'};
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    padding: ${({ noPadding }) => noPadding ? '0px 23px' : '0px 23px 14px'};
    box-sizing: border-box;
  }
`;

const Title2 = styled.h2`
  color: ${({ theme }) => theme.colors.navy};
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: 29px;
  margin: 0px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const Icon = styled.img`
  margin-right: 11px;
`;

const renderMostFrequentQuestions = () => (
  <MostFrequentContainer>
    <QuestionsContainer noPadding>
      <Row>
        <Icon src={mostFrequentIcon} />
        <Title2>Questions fréquentes</Title2>
      </Row>
      {mostFrequentQuestions.map((question) => (
        <Question
          key={question.question}
          question={question}
          alwaysOpened
        />
      ))}
    </QuestionsContainer>
  </MostFrequentContainer>
);

const renderOtherQuestions = () => {
  const questionsBlocks = [
    { title: 'Mesures de précaution', icon: otherIcon, questions: securityQuestions },
    { title: 'Le service', icon: serviceIcon, questions: serviceQuestions },
    { title: 'Les paiements', icon: paymentsIcon, questions: paymentsQuestions },
    { title: 'SAV', icon: customerCareIcon, questions: customerCareQuestions },
    { title: 'Autre', icon: otherIcon, questions: otherQuestions },
  ];
  return (
    <OtherQuestionsContainer>
      {questionsBlocks.map(({ title, icon, questions }) => (
        <QuestionsContainer key={title}>
          <Row>
            <Icon src={icon} />
            <Title2>{title}</Title2>
          </Row>
          {questions.map((question, index) => (
            <Question
              key={question.question}
              question={question}
              marginBottom={index === questions.length - 1}
              noTransition={index === 0}
            />
          ))}
        </QuestionsContainer>
      ))}
    </OtherQuestionsContainer>
  );
};

const FAQ = () => (
  <Container>
    <TitleContainer>
      <Title1>Vous avez besoin d’aide ?</Title1>
      <Dots noMargin />
    </TitleContainer>

    {renderMostFrequentQuestions()}

    {renderOtherQuestions()}
  </Container>
);

export default FAQ;
