import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { colors, mobileThresholdPixels } from './v3/styledComponents';
import { FormInput as StyledFormInput, Button as StyledButton } from './v2/styledComponents';
import downIcon from '../../assets/faq/downIcon.svg';
import upIcon from '../../assets/faq/upIcon.svg';
import downIconSmall from '../../assets/faq/downIconSmall.svg';
import upIconSmall from '../../assets/faq/upIconSmall.svg';
import checkZipcodeError from '../../core/services/zipcode';
import errorLogo from '../../assets/error.png';

const Container = styled.div`
  position: relative;
  ${({ hasLeftPosition }) => hasLeftPosition && 'left: 29px;'}
  outline: none;

  @media (max-width: ${mobileThresholdPixels}) {
    left: ${({ alwaysOpened }) => alwaysOpened ? '0' : '29'}px;
    padding-right: ${({ alwaysOpened }) => alwaysOpened ? '0' : '29'}px;
  }
`;

const QuestionContainer = styled.div`
  cursor: ${({ alwaysOpened }) => alwaysOpened ? 'default' : 'pointer'};
`;

const QuestionTitle = styled.h4`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 22px;
  margin-top: ${({ isOpened, noTransition }) => isOpened && !noTransition ? '52' : '36'}px;
  ${({ noTransition }) => !noTransition && 'transition: margin-top 0.2s ease-in-out;'}
  font-weight: 500;
  margin-bottom: ${({ isOpened }) => isOpened ? '10' : '36'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const Answer = styled.p`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  margin: 0px;
  &:last-of-type {
    ${({ hasMarginBottom }) => hasMarginBottom && 'margin-bottom: 36px;'}
  }
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: -29px;
  top: 7px;
`;

const Bold = styled.span`
  font-weight: 500;
  cursor: pointer;
`;

const FormInputContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  align-items: center;
  position: relative;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }
`;

const FormInput = styled(StyledFormInput)`
  width: 226px;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-size: 18px;
  &::placeholder {
    color: ${colors.lightGrey4};
  }
  &:focus {
    outline: none;
  }
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    width: 45%;
  }
`;

const Button = styled(StyledButton)`
  margin: 0px 0px 0px 10px;
  height: 40px;
  min-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: none;
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px 0px 10px;
    height: 40px;
    min-height: unset;
    min-width: unset;
    width: 45%;
  }
`;

const ResponseText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.navy};
  position: absolute;
  margin: 0px;
  top: 75px;
`;

const ErrorText = styled(ResponseText)`
  color: ${colors.red};
`;

const FormAndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
`;

const I = styled.i`
  background-image: url(${errorLogo});
  background-size: contain;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 225px;
  @media (max-width: ${mobileThresholdPixels}) {
    left: 45%;
  }
`;

const FormattedSpan = styled.span`
  ${({ isBold }) => isBold && 'font-weight: 500;'}
  ${({ isItalic }) => isItalic && 'font-style: italic;'}
`;

/* eslint-disable react/no-array-index-key */
const toFormattedItalicText = (text) => {
  const splitText = text.split('<italic>').filter((line) => line !== '');
  if (text.indexOf('<italic>') === 0) {
    return splitText.map((line, index) => (
      <FormattedSpan key={`${line}${index}`} isItalic={index % 2 === 0}>
        {line}
      </FormattedSpan>
    ));
  }
  return splitText.map((line, index) => (
    <FormattedSpan key={`${line}${index}`} isItalic={index % 2 !== 0}>
      {line}
    </FormattedSpan>
  ));
};

const toFormattedBoldAndItalicText = (text) => {
  const splitText = text.split('<bold>').filter((line) => line !== '');
  if (text.indexOf('<bold>') === 0) {
    return splitText.map((line, index) => (
      <FormattedSpan key={`${line}${index}`} isBold={index % 2 === 0}>
        {toFormattedItalicText(line)}
      </FormattedSpan>
    ));
  }
  return splitText.map((line, index) => (
    <FormattedSpan key={`${line}${index}`} isBold={index % 2 !== 0}>
      {toFormattedItalicText(line)}
    </FormattedSpan>
  ));
};

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.alwaysOpened,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    const { alwaysOpened } = this.props;
    const { isOpened } = this.state;
    if (alwaysOpened) return;
    this.setState({ isOpened: !isOpened });
  }

  checkZipcode = () => {
    const { zipcode } = this.state;
    const errorCode = checkZipcodeError(zipcode).code;
    this.setState({
      isOk: errorCode === 0,
      formatError: errorCode === 1,
      notDeliveredError: errorCode === 2,
    });
  }

  renderZipCodeQuestion = (answer) => {
    const {
      isOk, formatError, notDeliveredError, zipcode,
    } = this.state;
    return (
      <React.Fragment key={answer}>
        <Answer key={answer}>
          {'- Vous voulez savoir si Tilli se déplace chez vous ? '}
          <Bold>
            Testez votre code postal :
          </Bold>
        </Answer>
        <FormAndMessageContainer>
          <FormInputContainer>
            <FormInput
              name="zipcode"
              type="numeric"
              placeholder="Ex: 91344"
              onChange={(e) => this.setState({ zipcode: e.target.value })}
              value={zipcode || ''}
            />
            {formatError === true && <I />}
            <Button onClick={this.checkZipcode} navy>Valider</Button>
          </FormInputContainer>
          {isOk
            && <ResponseText>Nos Tillistes peuvent venir jusqu’à chez vous ;)</ResponseText>}
          {formatError && <ErrorText>Code postal non valide</ErrorText>}
          {notDeliveredError
            && <ErrorText>Nos Tillistes ne peuvent pas encore venir chez vous :(</ErrorText>}
        </FormAndMessageContainer>
      </React.Fragment>
    );
  }

  render() {
    const {
      question, alwaysOpened, context: { isMobile }, marginBottom, noTransition, hasBeginHyphen, hasLeftPosition,
    } = this.props;
    const { isOpened } = this.state;
    return (
      <Container key={question.question} hasLeftPosition={hasLeftPosition} alwaysOpened={alwaysOpened}>
        <QuestionContainer onClick={this.toggle} alwaysOpened={alwaysOpened}>
          {!alwaysOpened && !isOpened && <Icon src={isMobile ? downIconSmall : downIcon} />}
          {!alwaysOpened && isOpened && <Icon src={isMobile ? upIconSmall : upIcon} />}
          <QuestionTitle isOpened={isOpened} noTransition={noTransition}>{question.question}</QuestionTitle>
        </QuestionContainer>
        {isOpened
          && question.answers.map((answer) => {
            if (!answer) return <br />;
            return (answer === 'ZIPCODE'
              ? (this.renderZipCodeQuestion(answer))
              : (
                <Answer key={answer} hasMarginBottom={marginBottom}>
                  {typeof answer === 'object'
                    ? answer
                    : toFormattedBoldAndItalicText(`${(question.answers.length !== 1 && hasBeginHyphen) ? '- ' : ''}${answer}`)}
                </Answer>
              )
            );
          })}
      </Container>
    );
  }
}

Question.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.node),
  }).isRequired,
  alwaysOpened: PropTypes.bool,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  marginBottom: PropTypes.bool,
  noTransition: PropTypes.bool,
  hasBeginHyphen: PropTypes.bool,
  hasLeftPosition: PropTypes.bool,
};

Question.defaultProps = {
  alwaysOpened: false,
  marginBottom: false,
  noTransition: false,
  hasBeginHyphen: true,
  hasLeftPosition: true,
};

export default withAppContext(Question);
